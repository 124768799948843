import React, { useRef, useEffect, useState, useContext } from "react";
import "./../styles/Map.css";
import { usePosition } from "use-position";
import { GeneralContext } from "../context/GeneralContext";

import mapboxgl from "mapbox-gl";
mapboxgl.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

mapboxgl.accessToken =
    "pk.eyJ1IjoiZGF0YW1vbmtleXMiLCJhIjoiY2t1bGF6aHNnMTBndDMxbzgwbWhkZWJzciJ9.DwM2VIXpUeRUZZJEW2VrhA";

const Map = ({ sights, setTheme, users }) => {
    const { latitude, longitude } = useContext(GeneralContext);
    const mapContainerRef = useRef(null);
    const [lng, setLng] = useState(10);
    const [lat, setLat] = useState(10);
    const [zoom, setZoom] = useState(0);
    const [showUsers, setShowUsers] = useState(true);
    const [auxUsers, setAuxUsers] = useState(users);
    let elementArray = [];

    let map;

    const test = usePosition();
    const zoomThreshold = 3.5;
    const zoomUsers = 8.5;

    // Initialize map when component mounts
    useEffect(() => {
        map = new mapboxgl.Map({
            style: "mapbox://styles/datamonkeys/clbaa3oe4000115og9t4cjfms",
            center: [latitude || lat, longitude || lng],
            zoom: zoom,
            pitch: 0,
            bearing: 0,
            container: mapContainerRef.current,
            antialias: true,
            projection: "globe"
        });

        // Add navigation control (the +/- zoom buttons)
        // map.addControl(new mapboxgl.NavigationControl(), "top-right");
        map.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true
            })
        );

        // map.on("move", () => {
        //     setLng(map.getCenter().lng.toFixed(4));
        //     setLat(map.getCenter().lat.toFixed(4));
        //     setZoom(map.getZoom().toFixed(2));
        // });

        map.on("load", () => {
            // console.log("asd", sights);
            // map.setFog({});

            // Insert the layer beneath any symbol layer.
            const layers = map.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === "symbol" && layer.layout["text-field"]
            ).id;

            // const flyTo = (latitude, longitude) => {
            //     window.fly(data.latitude, data.longitude);
            // };

            {
                sights.length > 0 &&
                    sights.map(function (data, i) {
                        const date = data.date;

                        const el = document.createElement("div");
                        el.className = `marker type-${data.type}`;
                        el.setAttribute("latitude", data.latitude);
                        el.setAttribute("longitude", data.longitude);
                        el.setAttribute("id", data.id);

                        // console.log(date);
                        if (date) {
                            if (
                                (Date.now() - new Date(date)) / (60 * 1000) <
                                5
                            ) {
                                el.className = el.className + " red-alert";
                            }
                        }

                        el.style.backgroundImage = "none !important";
                        // el.removeEventListener("click", flyTo);
                        // el.addEventListener("click", flyTo);

                        data.latitude &&
                            data.longitude &&
                            data.type &&
                            new mapboxgl.Marker(el)
                                .setLngLat([data.longitude, data.latitude])
                                .addTo(map);
                    });
            }

            {
                users.forEach(function (data) {
                    if (data.latitude && data.longitude) {
                        const el = document.createElement("div");
                        el.className = `users`;

                        new mapboxgl.Marker(el)
                            .setLngLat([data.longitude, data.latitude])
                            .addTo(map);
                    }
                });
            }

            // The 'building' layer in the Mapbox Streets
            // vector tileset contains building height data
            // from OpenStreetMap.
            map.addLayer(
                {
                    id: "add-3d-buildings",
                    source: "composite",
                    "source-layer": "building",
                    filter: ["==", "extrude", "true"],
                    type: "fill-extrusion",
                    minzoom: 15,
                    paint: {
                        "fill-extrusion-color": "#aaa",

                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        "fill-extrusion-height": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            15,
                            0,
                            15.05,
                            ["get", "height"]
                        ],
                        "fill-extrusion-base": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            15,
                            0,
                            15.05,
                            ["get", "min_height"]
                        ],
                        "fill-extrusion-opacity": 0.6
                    }
                },
                labelLayerId
            );
        });

        map.on("zoom", () => {
            // console.log(map.getZoom());
            if (map.getZoom() > zoomThreshold) {
                setTheme("dark");
            } else {
                setTheme("light");
            }

            // console.log(map.getZoom());

            if (map.getZoom() > zoomUsers) {
                setShowUsers(true);
            } else {
                setShowUsers(false);
            }
        });

        const fly = (lat, long) => {
            //console.log(latitude, longitude, test);

            if (lat && long) {
                map.flyTo({
                    zoom: 18,
                    center: [long, lat],
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                });
            } else if (latitude && longitude) {
                map.flyTo({
                    zoom: 18,
                    center: [longitude, latitude],
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                });
            }
        };

        window.fly = fly;

        // Clean up on unmount
        return () => map.remove();
    }, [sights.length]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (map && latitude && longitude) {
            map.flyTo({
                zoom: 2,
                center: [longitude, latitude],
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        }
    }, [latitude, longitude]);

    return (
        <div>
            {/*<div className="sidebarStyle">*/}
            {/*    <div>*/}
            {/*        Longitude: {longitude || lng} | Latitude: {latitude || lat}{" "}*/}
            {/*        | Zoom: {zoom}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div
                className={`map-container users-${!showUsers}`}
                ref={mapContainerRef}
            />
        </div>
    );
};

export default Map;
