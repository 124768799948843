import db from "../utils/firestore";
// import { collection, query, where, onSnapshot } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

import React, { useState, useEffect } from "react";
import { usePosition } from "use-position";
import "../styles/TabBar.css";

import plus from "../imgs/plus.png";
import close from "../imgs/close.png";
import check from "../imgs/check.png";
import meteor from "../imgs/sighted/meteor-dark.png";
import alien from "../imgs/sighted/alien-dark.png";
import lightball from "../imgs/sighted/lightball-dark.png";
import ship from "../imgs/sighted/ship-dark.png";

// new
import bomb from "../imgs/bomb.png";
import earthquake from "../imgs/earthquake.png";
import flood from "../imgs/flood.png";
import gun from "../imgs/gun.png";
import storm from "../imgs/storm.png";
import volcano from "../imgs/volcano.png";
import fire from "../imgs/fire.png";
import fireBr from "../imgs/fire-br.png";
import fireFight from "../imgs/fire-fight.png";
import fora from "../imgs/fora.png";
import tank from "../imgs/tank.png";
import gunBR from "../imgs/gun-br.png";
import knife from "../imgs/knife.png";
import store from "../imgs/apple-store.png";

function verifiedCallback(token) {
    // console.log("Captcha token: " + token);
}

function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

function TabBar({ sights, updateData, theme, hidePopup }) {
    const { latitude, longitude, error } = usePosition();
    const [stage, setStage] = useState(0);
    const [type, setType] = useState("");
    const [alertError, setError] = useState(error);
    const numbers = [1, 2, 3, 4, 5];
    const shuffledNumbers = shuffleArray(numbers);

    useEffect(() => {
        // console.log(error);
        setError(error);
        if (alertError === "User denied Geolocation") {
            alert("You need to enable locations to use this app.");
        }
    }, [alertError]);

    async function saveToDb() {
        const docRef = await addDoc(collection(db, "SightsWeb"), {
            coordinate: {
                latitude: latitude,
                longitude: longitude
            },
            image: type || "alien" + "-dark",
            title: type || "alien" + "-dark",
            type: type || "alien" + "-dark",
            date: new Date()
        });

        //setDoc(cityRef, );
        // console.log({
        //     coordinate: {
        //         latitude: latitude,
        //         longitude: longitude
        //     },
        //     image: type + "-dark",
        //     title: type + "-dark",
        //     type: type + "-dark"
        // });
    }

    return (
        <>
            {
                <div className={"footer-tab"}>
                    {/*<div>{error}</div>*/}
                    {/*{stage === 0 ? (*/}
                    {/*    <div*/}
                    {/*        onClick={() => {*/}
                    {/*            hidePopup(true);*/}
                    {/*            setStage(1);*/}
                    {/*            window.fly();*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <img src={plus} className="icons" />*/}
                    {/*    </div>*/}
                    {/*) : stage === 1 ? (*/}
                    {/*    <div>*/}
                    {/*        <div className="calc">2 + 2 = ?</div>*/}
                    {/*        <ul className="captcha">*/}
                    {/*            {shuffledNumbers.map((number) => {*/}
                    {/*                if (number === 4) {*/}
                    {/*                    return (*/}
                    {/*                        <li*/}
                    {/*                            key={"key-li" + number}*/}
                    {/*                            onClick={() => setStage(2)}*/}
                    {/*                        >*/}
                    {/*                            {number}*/}
                    {/*                        </li>*/}
                    {/*                    );*/}
                    {/*                } else {*/}
                    {/*                    return (*/}
                    {/*                        <li*/}
                    {/*                            key={"key-li" + number}*/}
                    {/*                            onClick={() => setStage(0)}*/}
                    {/*                        >*/}
                    {/*                            {number}*/}
                    {/*                        </li>*/}
                    {/*                    );*/}
                    {/*                }*/}
                    {/*            })}*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*) : stage === 2 ? (*/}
                    {/*    <div className="box">*/}
                    {/*        <div>*/}
                    {/*            <img*/}
                    {/*                onClick={() => {*/}
                    {/*                    setStage(3);*/}
                    {/*                    setType("ship");*/}
                    {/*                }}*/}
                    {/*                src={ship}*/}
                    {/*                className="icons"*/}
                    {/*            />*/}
                    {/*            <img*/}
                    {/*                onClick={() => {*/}
                    {/*                    setStage(3);*/}
                    {/*                    setType("alien");*/}
                    {/*                }}*/}
                    {/*                src={alien}*/}
                    {/*                className="icons"*/}
                    {/*            />*/}
                    {/*            <img*/}
                    {/*                onClick={() => {*/}
                    {/*                    setStage(3);*/}
                    {/*                    setType("lightball");*/}
                    {/*                }}*/}
                    {/*                src={lightball}*/}
                    {/*                className="icons"*/}
                    {/*            />*/}
                    {/*            <img*/}
                    {/*                onClick={() => {*/}
                    {/*                    setStage(3);*/}
                    {/*                    setType("meteor");*/}
                    {/*                }}*/}
                    {/*                src={meteor}*/}
                    {/*                className="icons"*/}
                    {/*            />*/}
                    {/*            <img*/}
                    {/*                onClick={() => {*/}
                    {/*                    setStage(0);*/}
                    {/*                    setType("");*/}
                    {/*                }}*/}
                    {/*                src={close}*/}
                    {/*                className="icons"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*    <div>*/}
                    {/*        <div className="calc">*/}
                    {/*            Confirm {type} at your location?*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <img*/}
                    {/*                onClick={async () => {*/}
                    {/*                    await saveToDb();*/}
                    {/*                    setStage(0);*/}
                    {/*                    await updateData();*/}
                    {/*                }}*/}
                    {/*                src={check}*/}
                    {/*                className="icons"*/}
                    {/*            />*/}
                    {/*            <img*/}
                    {/*                onClick={() => setStage(2)}*/}
                    {/*                src={close}*/}
                    {/*                className="icons"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {/*{stage === 0 && (*/}
                    {/*<>*/}
                    {/*    <a href="https://apps.apple.com/en/app/sighted/id1626762414">*/}
                    {/*        <img src={store} className={"storeButton"} />*/}
                    {/*    </a>*/}
                    {/*    /!*<div className={`${theme} counter`}>{sights}</div>*!/*/}
                    {/*</>*/}
                    {/*)}*/}
                </div>
            }
        </>
    );
}

export default TabBar;
