import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyB2_jvD2c7HEcQ3mS2K4N-vMN0tpV049zg",
    authDomain: "sights-c137.firebaseapp.com",
    projectId: "sights-c137",
    storageBucket: "sights-c137.appspot.com",
    messagingSenderId: "614305101326",
    appId: "1:614305101326:web:e2e1293ccb31c1ddfd6e5f",
    measurementId: "G-NW351C5ZSC"
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
const db = getFirestore();

export default db;

export const onForegroundMessage = () =>
    new Promise((resolve) =>
        onMessage(messaging, (payload) => resolve(payload))
    );

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
