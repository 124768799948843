import "../styles/Support.css";

function Support() {
    return (
        <div className="support">
            <strong>Support Page</strong>
            <p>
                If you have any questions or suggestions, do not hesitate to
                contact me at louis@datamonkeys.ai.
            </p>
        </div>
    );
}

export default Support;
