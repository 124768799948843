import React, { useRef, useEffect, useState, useContext } from "react";
import "./../styles/Map.css";
import { usePosition } from "use-position";
import { GeneralContext } from "../context/GeneralContext";

import mapboxgl from "mapbox-gl";
mapboxgl.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

mapboxgl.accessToken =
    "pk.eyJ1IjoiZGF0YW1vbmtleXMiLCJhIjoiY2t1bGF6aHNnMTBndDMxbzgwbWhkZWJzciJ9.DwM2VIXpUeRUZZJEW2VrhA";

const Map = ({ sights, users, setTheme }) => {
    // console.log(sights.length);
    const mapContainerRef2 = useRef(null);

    const [lng, setLng] = useState(10);
    const [lat, setLat] = useState(10);
    const [zoom, setZoom] = useState(0);

    let map;
    const zoomThreshold = 3.5;

    // Initialize map when component mounts
    useEffect(() => {
        map = new mapboxgl.Map({
            style: "mapbox://styles/datamonkeys/clbaa3oe4000115og9t4cjfms",
            center: [lat, lng],
            zoom: zoom,
            pitch: 0,
            bearing: 0,
            container: mapContainerRef2.current,
            antialias: true,
            projection: "globe"
        });

        // map.on("move", () => {
        //     setLng(map.getCenter().lng.toFixed(4));
        //     setLat(map.getCenter().lat.toFixed(4));
        //     setZoom(map.getZoom().toFixed(2));
        // });

        map.on("load", () => {
            // console.log("asd", sights);
            // map.setFog({});

            // Insert the layer beneath any symbol layer.
            const layers = map.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === "symbol" && layer.layout["text-field"]
            ).id;

            {
                // console.log("asd", sights.length);
                sights.length > 0 &&
                    sights.map(function (data, i) {
                        const date = data.date;

                        const el = document.createElement("div");
                        el.className = `marker type-${data.type}`;

                        //console.log(date);
                        if (date) {
                            if (
                                (Date.now() - new Date(date)) / (60 * 1000) <
                                5
                            ) {
                                el.className = el.className + " red-alert";
                            }
                        }

                        el.style.backgroundImage = "none !important";
                        // el.removeEventListener("click", flyTo);
                        // el.addEventListener("click", flyTo);

                        data.latitude &&
                            data.longitude &&
                            data.type &&
                            new mapboxgl.Marker(el)
                                .setLngLat([data.longitude, data.latitude])
                                .addTo(map);
                    });
            }

            map.addLayer(
                {
                    id: "add-3d-buildings",
                    source: "composite",
                    "source-layer": "building",
                    filter: ["==", "extrude", "true"],
                    type: "fill-extrusion",
                    minzoom: 15,
                    paint: {
                        "fill-extrusion-color": "#aaa",

                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        "fill-extrusion-height": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            15,
                            0,
                            15.05,
                            ["get", "height"]
                        ],
                        "fill-extrusion-base": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            15,
                            0,
                            15.05,
                            ["get", "min_height"]
                        ],
                        "fill-extrusion-opacity": 0.6
                    }
                },
                labelLayerId
            );
        });

        map.on("zoom", () => {
            if (map.getZoom() > zoomThreshold) {
                setTheme("dark");
            } else {
                setTheme("light");
            }
        });

        // Clean up on unmount
        return () => map.remove();
    }, [sights.length]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="map-container" ref={mapContainerRef2} />
        </div>
    );
};

export default Map;
