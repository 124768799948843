import { getToken, getMessaging, onMessage } from "firebase/messaging";

export const getOrRegisterServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        return window.navigator.serviceWorker
            .getRegistration("./firebase-push-notification-scope")
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                // getFirebaseToken();
                return window.navigator.serviceWorker.register(
                    "./firebase-messaging-sw.js",
                    {
                        scope: "./firebase-push-notification-scope"
                    }
                );
            });
    }
    throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
    getOrRegisterServiceWorker().then(async (serviceWorkerRegistration) => {
        // console.log(serviceWorkerRegistration);
        const messaging = getMessaging();
        // console.log(messaging.vapidKey);

        // onMessage(messaging, (payload) => {
        //     // alert(555);
        //     console.log("Message received. ", payload);
        //     const location = JSON.parse(payload.data.location);
        //     showNotification(
        //         payload.notification.body,
        //         payload.notification.image,
        //         location
        //     );
        //     // ...
        // });

        // console.log(process.env.REACT_APP_VAPID_KEY);

        // messaging.onBackgroundMessage((payload) => {
        //     console.log("Received background message: ", payload);
        //
        //     const notificationTitle = payload.notification.title;
        //     const notificationOptions = { body: payload.notification.body };
        //
        //     showNotification(notificationTitle, notificationOptions);
        // });

        if (Notification.permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: messaging.vapidKey,
                serviceWorkerRegistration
            });

            return token;
        }

        // console.log("Token", token);
    });

export const showNotification = (body, image, location) => {
    if (Notification.permission !== "granted") {
        Notification.requestPermission();
    } else {
        // const options = {
        //     body: body,
        //     dir: "ltr",
        //     image: image
        // };
        // const notification = new Notification("Notification", options);
        //
        // notification.onclick = function () {
        //     var myTab = window.open("https://sighted.app", "_blank");
        //     if (myTab != null && !myTab.closed) {
        //         window.fly(location.latitude, location.longitude);
        //     } else {
        //         window.open("https://sighted.app");
        //         setTimeout(() => {
        //             window.fly(location.latitude, location.longitude);
        //         }, 500);
        //     }
        // };
    }
};
