import Old from "./Old";
import { ContextProvider } from "./context/utils/ContextProvider";
import { onMessageListener } from "./utils/firestore";
import { showNotification } from "./utils/serviceWorker";

function App() {
    onMessageListener()
        .then((payload) => {
            // showNotification({
            //     title: payload.notification.title,
            //     body: payload.notification.body,
            //     icon: "./logo512.png"
            // });
            // console.log(payload);
        })
        .catch((err) => console.log("failed: ", err));

    return (
        <ContextProvider>
            <div className="App">
                <Old />
            </div>
        </ContextProvider>
    );
}

export default App;
