import React, { useEffect, useState } from "react";
import { usePosition } from "use-position";

export const GeneralContext = React.createContext();

export const GeneralProvider = ({ children }) => {
    const { latitude: lat, longitude: long, error } = usePosition();

    const [latitude, setLatitude] = useState(false);
    const [longitude, setLongitude] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [theme, setTheme] = useState("normal");

    useEffect(() => {
        getLocation();
    }, []);

    const getLocation = () => {
        if (!navigator.geolocation) {
            alert("Geolocation is not supported by your browser, sorry :(");
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (
                        position.coords.latitude == 0 &&
                        position.coords.longitude == 0
                    ) {
                        setTimeout(() => {
                            // console.log("trying...");
                            getLocation();
                        }, 200);
                    } else {
                        // console.log("ok");
                        // setLatitude(position.coords.latitude);
                        // setLongitude(position.coords.longitude);
                    }
                },
                () => {}
            );
            setShowMap(true);
        }
    };

    return (
        <GeneralContext.Provider
            value={{
                getLocation,
                latitude: latitude || lat || 0,
                setLatitude,
                longitude: longitude || long || 0,
                setLongitude,
                showMap,
                setShowMap,
                theme,
                setTheme
            }}
        >
            {children}
        </GeneralContext.Provider>
    );
};
